import 
// React, 
{ Suspense } 
from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
import App from "./App";
// import ReactPWAInstallProvider from "react-pwa-install";
// import reportWebVitals from "./reportWebVitals";
// import { HashRouter } from "react-router-dom";

import Loader from "./layouts/loader/Loader";
import { initializeApp } from "firebase/app";
// import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getMessaging, getToken , onMessage  } from "firebase/messaging";
Notification.requestPermission().then((permission) => {
  if (permission === 'granted') {
    console.log('Notification permission granted.');
  } else {
    console.log('Unable to get permission to notify.');
  }
});
const firebaseConfig = {
  serviceAccountId: 'firebase-adminsdk-qlj63@kerjakcom.iam.gserviceaccount.com',
  apiKey: "AIzaSyDf5Df6sfn2-z9BhLk1iHDdZxHlK53DDqE",
  authDomain: "kerjakcom.firebaseapp.com",
  databaseURL: "https://kerjakcom-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "kerjakcom",
  storageBucket: "kerjakcom.appspot.com",
  messagingSenderId: "412424017524",
  appId: "1:412424017524:web:43b0cef1c58423d46c6321",
  measurementId: "G-4DX2D83R8P"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
getPerformance(app);

const messaging = getMessaging(app);
getToken(messaging, { vapidKey: 'BEgpFOD4tXC6S_ckKxxPosmBYvd9qkls3i4v1L-v2d8VXXTR3aQ5LaqmdRUKXOG23sjY4v_BoNPrMgM9bCKEZAI' }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});
// const auth = getAuth(app);

// onAuthStateChanged(auth, (user) => {
// if (user) {
//   // User is signed in, see docs for a list of available properties
//   // https://firebase.google.com/docs/reference/js/firebase.User
//     // const uid = user.uid;
//     // console.log(uid)
    
//     localStorage.setItem('kerjak-user',JSON.stringify(auth.currentUser))
//     localStorage.setItem('user-uuid',user.uid)
//   // ...
// } else {
//   signInAnonymously(auth)
//   .then(() => {
//     // Signed in..
//   });
// }
// });
ReactDOM.render(
  <Suspense fallback={<Loader />}>
      <App />
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
